'use client'
import { useTranslations } from 'next-intl'
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  MarkerClustererF
} from '@react-google-maps/api'
import {
  googleMapsApiKey,
  containerStyle,
  center,
  zoom,
  clusterIconStyles,
  markerClustererCalculator,
  clustererMaxZoom,
  getMapBounds,
  getMarkerIcon,
  onZoomIn,
  onZoomOut,
  selectDot
} from '@/app/utils/mapHelpers'
import { fetchOffices, fetchCountryRegions, fetchCities } from '@/app/api/contacts/apiContacts'
import { getActiveLang } from '@/app/utils/getActiveLang'

import ReactSelect from 'react-select'
import Icon from '@/app/components/Ui/Icon'
import Pagination from '@/app/components/Ui/Pagination'
import { CSSTransition } from 'react-transition-group'
import useEventInitializer from '@/app/hooks/useEventInitializer'

import styles from './MapWithOffices.module.scss'

import pin1 from '@/app/assets/images/pins/1.svg'
import pin2 from '@/app/assets/images/pins/2.svg'

const PAGE_SIZE = 10

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size)
}

const MapWithOffices = forwardRef(({ title, lang, }, ref) => {
  const t = useTranslations('map')
  const types_options = useMemo(() => [
    {
      label: t('office'),
      value: 'office', // general, ingo
      color: '#499BD5',
      marker: pin2.src
    },
    {
      label: t('agent'),
      value: 'agent',
      color: '#4CA465',
      marker: pin1.src
    },
  ], [t])

  const mapRef = useRef(null)
  const legendsRef = useRef(null)
  const listRef = useRef(null)

  const [map, setMap] = useState(null)
  const [legendsIsOpen, setLegendsIsOpen] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [markers, setMarkers] = useState([])
  const [filteredMarkers, setFilteredMarkers] = useState([])

  const [localList, setLocalList] = useState([])
  const [localPagination, setLocalPagination] = useState({ page: 1, pageSize: PAGE_SIZE, total: 0 })
  const [currentPage, setCurrentPage] = useState(1)
  const [activePages, setActivePages] = useState([])

  const [regionOptions, setRegionOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [filteredCityOptions, setFilteredCityOptions] = useState([])

  const [typeIsFocus, setTypeIsFocus] = useState(false)
  const [regionIsFocus, setRegionIsFocus] = useState(false)
  const [cityIsFocus, setCityIsFocus] = useState(false)

  const [selectedType, setSelectedType] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [query, setQuery] = useState('')

  const activeLang = useMemo(() => {
    return getActiveLang(lang)
  }, [lang])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey
  })

  const centerMarkers = useCallback((map) => {
    const bounds = getMapBounds(map, window.google.maps, filteredMarkers)

    map.fitBounds(bounds)
  }, [filteredMarkers])

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onChangePage = async (page, isLoadMore = false) => {
    if (!page || isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const res = paginate(filteredMarkers, localPagination.pageSize, page)
      if (isLoadMore) {
        setLocalList([...localList, ...res])
        setActivePages([...activePages, currentPage])
      } else {
        setLocalList(res)
        setActivePages([])
      }
      setLocalPagination({
        page: page,
        pageSize: localPagination.pageSize,
        total: filteredMarkers.length
      })
      setCurrentPage(page)
      if (listRef.current && !isLoadMore) {
        listRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    } catch (error) {
      console.warn(error, '-> onChangePage')
    } finally {
      setIsLoading(false)
    }
  }

  const handleShowOnMap = (place) => {
    if (!map || !isLoaded || isLoading) return

    setSelectedPlace(place)
    map.setZoom(16)
    map.setCenter(new google.maps.LatLng(place.latitude, place.longitude))
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  const fetchData = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      const { offices } = await fetchOffices(activeLang)
      const { regions } = await fetchCountryRegions(activeLang)
      const { cities } = await fetchCities(activeLang)

      setMarkers(offices)
      setFilteredMarkers(offices)

      setLocalList(paginate(offices, localPagination.pageSize, localPagination.page))
      setLocalPagination({ page: 1, pageSize: localPagination.pageSize, total: offices.length })

      setRegionOptions(regions)
      setCityOptions(cities)
      setFilteredCityOptions(cities)
    } catch (error) {
      console.warn(error, '-> MapWithOffices fetchData')
    } finally {
      setIsLoading(false)
    }
  }

  const getAddressHref = (place) => {
    const { latitude, longitude, addressLink } = place;

    if (addressLink) return addressLink

    return `https://www.google.com/maps/place/${latitude},${longitude}`
  }

  useEventInitializer(() => {
    fetchData()
  })

  useEffect(() => {
    if (!selectedRegion?.value) {
      return setFilteredCityOptions(cityOptions)
    }

    setFilteredCityOptions(cityOptions.filter((city) => {
      return city?.region?.id === selectedRegion.value
    }))

    if (selectedCity?.region?.value !== selectedRegion.value) {
      setSelectedCity(null)
    }
  }, [selectedRegion, cityOptions, setSelectedCity, setFilteredCityOptions])

  useEffect(() => {
    setSelectedPlace(undefined)
    setActivePages([])
    setCurrentPage(1)

    if (!selectedType && !selectedRegion && !selectedCity && !query) {
      setFilteredMarkers(markers)
      setLocalList(paginate(markers, localPagination.pageSize, localPagination.page))
      setLocalPagination({ page: 1, pageSize: localPagination.pageSize, total: markers.length })

      return
    }

    setFilteredMarkers(null) // fixes a performance issue
    setTimeout(() => {
      const filter = markers.filter((marker) => {
        const filteredByType = selectedType ? selectedType.value === marker.officeType : true
        const filteredByRegion = selectedRegion ? selectedRegion.value === marker?.region?.id : true
        const filteredByCity = selectedCity ? selectedCity.value === marker?.city?.id : true
        const filteredByQuery = query ? marker.title.toLowerCase().includes(query.toLowerCase()) : true

        return filteredByType && filteredByRegion && filteredByCity && filteredByQuery
      })

      setFilteredMarkers(filter)
      setLocalList(paginate(filter, localPagination.pageSize, localPagination.page))
      setLocalPagination({ page: 1, pageSize: localPagination.pageSize, total: filter.length })
    }, 0)
  }, [
    selectedType,
    markers,
    selectedRegion,
    selectedCity,
    query,
    setFilteredMarkers
  ])

  useEffect(() => {
    if (!filteredMarkers?.length || !map || !isLoaded) return

    setTimeout(() => {
      centerMarkers(map)
    }, 0)
  }, [filteredMarkers, isLoaded, map, centerMarkers])

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (!legendsRef.current?.contains(event.target)) {
        setLegendsIsOpen(false)
      }
    }

    window.addEventListener("mousedown", handleOutSideClick)

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick)
    }
  }, [legendsRef])

  useImperativeHandle(ref, () => ({
    showOnMap(place) {
      handleShowOnMap(place)
    },
  }))

  return (
    <div
      className={classNames(
        styles['map-with-offices']
      )}
    >
      <div
        className={classNames(
          styles['map-with-offices_wrapper'],
          'wr'
        )}
      >
        <h2 className={styles['map-with-offices_title']}>{title}</h2>

        <div
          className={classNames(
            styles['map-with-offices_filter'],
            'd-f'
          )}
        >
          <div className='form_item'>
            <ReactSelect
              onChange={setSelectedType}
              onFocus={() => setTypeIsFocus(true)}
              onBlur={() => setTypeIsFocus(false)}
              placeholder={''}
              options={types_options}
              isClearable={true}
              classNamePrefix="select"
              noOptionsMessage={() => t('noOptionsMessage')}
              loadingMessage={() => t('loadingMessage')}
              className={classNames(
                'select select-with-dot',
                {
                  'b-has-value': selectedType || typeIsFocus
                }
              )}
              styles={{
                option: (styles, { data }) => ({ ...styles, ...selectDot(data.color) }),
                singleValue: (styles, { data }) => ({ ...styles, ...selectDot(data.color) })
              }}
            />
            <label className='form_placeholder'>
              {t('allType')}
            </label>
          </div>

          <div className='form_item'>
            <ReactSelect
              onChange={setSelectedRegion}
              onFocus={() => setRegionIsFocus(true)}
              onBlur={() => setRegionIsFocus(false)}
              placeholder={''}
              options={regionOptions}
              isClearable={true}
              isLoading={isLoading}
              classNamePrefix="select"
              noOptionsMessage={() => t('noOptionsMessage')}
              loadingMessage={() => t('loadingMessage')}
              className={classNames(
                'select',
                {
                  'b-has-value': selectedRegion || regionIsFocus
                }
              )}
            />
            <label className='form_placeholder'>
              {t('region')}
            </label>
          </div>

          <div className='form_item'>
            <ReactSelect
              onChange={setSelectedCity}
              onFocus={() => setCityIsFocus(true)}
              onBlur={() => setCityIsFocus(false)}
              placeholder={''}
              options={filteredCityOptions}
              isClearable={true}
              isLoading={isLoading}
              value={selectedCity || ''}
              classNamePrefix="select"
              noOptionsMessage={() => t('noOptionsMessage')}
              loadingMessage={() => t('loadingMessage')}
              className={classNames(
                'select',
                {
                  'b-has-value': selectedCity || cityIsFocus
                }
              )}
            />
            <label className='form_placeholder'>
              {t('city')}
            </label>
          </div>

          <div className='form_item'>
            <Icon
              className='form_input-right-icon'
              name={'search'}
              width={24}
              height={24}
              viewBox="0 0 25 25"
            />
            <input
              id='form-map-offices-search'
              className='input input--right-icon'
              type='text'
              placeholder={t('search')}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
            <label htmlFor='form-map-offices-search' className='form_placeholder'>
              {t('search')}
            </label>
          </div>
        </div>

        <div
          ref={mapRef}
          className={styles['map-with-offices_map']}
        >
          {isLoaded && (
            <>
              <GoogleMap
                options={{
                  disableDefaultUI: true
                }}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {Array.isArray(filteredMarkers) && filteredMarkers.length !== 0 && (
                  <MarkerClustererF
                    options={{
                      averageCenter: true,
                      calculator: markerClustererCalculator,
                      styles: clusterIconStyles,
                      maxZoom: clustererMaxZoom,
                    }}
                    onUnmount={(clusterer) => clusterer.clearMarkers()}
                  >
                    {(clusterer) => (
                      <>
                        {filteredMarkers.map((place) => (
                          <MarkerF
                            key={place.id}
                            onClick={() => {
                              place === selectedPlace
                                ? setSelectedPlace(undefined)
                                : setSelectedPlace(place)
                            }}
                            position={{
                              lat: place.latitude,
                              lng: place.longitude
                            }}
                            icon={getMarkerIcon(types_options, place.officeType)}
                            clusterer={clusterer}
                          ></MarkerF>
                        ))}
                      </>
                    )}
                  </MarkerClustererF>
                )}

                {selectedPlace && (
                  <InfoWindowF
                    position={{
                      lat: selectedPlace.latitude,
                      lng: selectedPlace.longitude
                    }}
                    zIndex={1}
                    options={{
                      pixelOffset: {
                        width: 0,
                        height: -40,
                      }
                    }}
                    onCloseClick={() => setSelectedPlace(undefined)}
                  >
                    <div className={styles['map-with-offices_infowindow']}>
                      <button
                        className={classNames(
                          styles['map-with-offices_infowindow-close'],
                          'd-f ai-c'
                        )}
                        type="button"
                        onClick={() => setSelectedPlace(undefined)}
                      >
                        <Icon
                          name={'cross'}
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                        />
                      </button>

                      <div
                        className={styles['map-with-offices_infowindow-title']}
                      >{selectedPlace.title}</div>

                      <div
                        className={styles['map-with-offices_infowindow-contacts']}
                      >
                        {Array.isArray(selectedPlace?.phones) && selectedPlace.phones.length !== 0 && selectedPlace.phones.map((value, index) => (
                          <div
                            key={index}
                            className={classNames(
                              styles['map-with-offices_infowindow-contacts-item'],
                              styles['map-with-offices_infowindow-contacts-item--phone'],
                            )}
                          >
                            <a
                              className={classNames(
                                styles['map-with-offices_infowindow-contacts-item-link'],
                                'd-if'
                              )}
                              href={`tel:${value}`}
                            >
                              <Icon
                                name='phone-blue'
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                              />
                              {value}
                            </a>
                          </div>
                        ))}

                        {Array.isArray(selectedPlace?.emails) && selectedPlace.emails.length !== 0 && selectedPlace.emails.map((value, index) => (
                          <div
                            key={index}
                            className={classNames(
                              styles['map-with-offices_infowindow-contacts-item']
                            )}
                          >
                            <a
                              className={classNames(
                                styles['map-with-offices_infowindow-contacts-item-link'],
                                'd-if'
                              )}
                              href={`mailto:${value}`}
                            >
                              <Icon
                                name='email-at'
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                              />
                              {value}
                            </a>
                          </div>
                        ))}

                        {selectedPlace?.address && (
                          <div
                            className={classNames(
                              styles['map-with-offices_infowindow-contacts-item']
                            )}
                          >
                            <a
                              className={classNames(
                                styles['map-with-offices_infowindow-contacts-item-link'],
                                'd-if'
                              )}
                              href={getAddressHref(selectedPlace)}
                              target="_blank"
                              rel="nofollow"
                            >
                              <Icon
                                name='location'
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                              />
                              {selectedPlace.address}
                            </a>
                          </div>
                        )}

                        {selectedPlace?.timeWork && (
                          <div
                            className={classNames(
                              styles['map-with-offices_infowindow-contacts-item']
                            )}
                          >
                            <span
                              className={classNames(
                                styles['map-with-offices_infowindow-contacts-item-link'],
                                'd-if'
                              )}
                            >
                              <Icon
                                name='schedule'
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                              />
                              <span className='ws-pl'>{selectedPlace.timeWork}</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </InfoWindowF>
                )}
              </GoogleMap>

              <div
                ref={legendsRef}
                className={styles['map-with-offices_legends']}
              >
                <button
                  className={classNames(
                    legendsIsOpen && styles['is-active'],
                    'd-f ai-c jc-c'
                  )}
                  type='button'
                  onClick={() => setLegendsIsOpen(!legendsIsOpen)}
                >
                  <Icon
                    name={'legends'}
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  />
                </button>

                <CSSTransition
                  in={legendsIsOpen}
                  timeout={300}
                  unmountOnExit
                  classNames="fade"
                >
                  <div
                    className={styles['map-with-offices_legends-list']}
                  >
                    <div
                      className={styles['map-with-offices_legends-list-content']}
                    >
                      {types_options.map((item) => (
                        <div
                          key={item.value}
                          className={classNames(
                            styles['map-with-offices_legends-item'],
                            'd-f ai-c'
                          )}
                        >
                          <span className='map-circle' style={{ backgroundColor: item.color }}></span>
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </CSSTransition>
              </div>

              <div className={styles['map-with-offices_zoom']}>
                <button
                  className='d-f ai-c jc-c'
                  type='button'
                  onClick={() => onZoomIn(map)}
                >
                  <Icon
                    name={'zoom-plus'}
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  />
                </button>
                <button
                  className='d-f ai-c jc-c'
                  type='button'
                  onClick={() => onZoomOut(map)}
                >
                  <Icon
                    name={'zoom-minus'}
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  />
                </button>
              </div>
            </>
          )}
        </div>

        {Array.isArray(localList) && localList.length !== 0 ? (
          <div
            ref={listRef}
            className={styles['map-with-offices_list']}
          >
            {localList.map((item) => (
              <div
                key={item.id}
                className={classNames(
                  styles['map-with-offices_list-item'],
                  'd-f'
                )}
              >
                <div className={classNames(
                  styles['map-with-offices_list-item-col'],
                  styles['map-with-offices_list-item-col--title']
                )}>
                  <div
                    className={classNames(
                      styles['map-with-offices_list-title'],
                      'h5'
                    )}
                  >{item?.title}</div>
                  {item.officeType === 'agent' && (
                    <div
                      className={classNames(
                        styles['map-with-offices_list-agent'],
                        'd-if ai-c'
                      )}
                    >
                      <Icon
                        name='agent'
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                      />
                      {t('agencyOffice')}
                    </div>
                  )}
                </div>

                <div className={classNames(
                  styles['map-with-offices_list-item-col'],
                  styles['map-with-offices_list-item-col--contacts'],
                  (!Array.isArray(item?.phones) || item.phones.length === 0 || !Array.isArray(item?.emails) || item.emails.length === 0) && styles['is-empty'],
                )}>
                  {Array.isArray(item?.phones) && item.phones.length !== 0 && item.phones.map((value, index) => (
                    <div
                      key={index}
                      className={classNames(
                        styles['map-with-offices_list-contact'],
                        styles['map-with-offices_list-contact--lg'],
                      )}
                    >
                      <a
                        className={classNames(
                          styles['map-with-offices_list-contact-link'],
                          'd-if'
                        )}
                        href={`tel:${value}`}
                      >
                        <Icon
                          name='phone-blue'
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                        />
                        {value}
                      </a>
                    </div>
                  ))}

                  {Array.isArray(item?.emails) && item.emails.length !== 0 && item.emails.map((value, index) => (
                    <div
                      key={index}
                      className={classNames(
                        styles['map-with-offices_list-contact'],
                        styles['map-with-offices_list-contact--lg'],
                      )}
                    >
                      <a
                        className={classNames(
                          styles['map-with-offices_list-contact-link'],
                          'd-if'
                        )}
                        href={`mailto:${value}`}
                      >
                        <Icon
                          name='email-at'
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                        />
                        {value}
                      </a>
                    </div>
                  ))}
                </div>

                <div className={classNames(
                  styles['map-with-offices_list-item-col'],
                  styles['map-with-offices_list-item-col--time'],
                  !item?.timeWork && styles['is-empty'],
                )}>
                  {item?.timeWork && (
                    <div
                      className={classNames(
                        styles['map-with-offices_list-contact']
                      )}
                    >
                      <span
                        className={classNames(
                          styles['map-with-offices_list-contact-link'],
                          'd-if'
                        )}
                      >
                        <Icon
                          name='schedule'
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                        />
                        <span className='ws-pl'>{item.timeWork}</span>
                      </span>
                    </div>
                  )}
                </div>

                <div className={classNames(
                  styles['map-with-offices_list-item-col'],
                  styles['map-with-offices_list-item-col--address']
                )}>
                  {item?.address && (
                    <div
                      className={classNames(
                        styles['map-with-offices_list-contact']
                      )}
                    >
                      <a
                        className={classNames(
                          styles['map-with-offices_list-contact-link'],
                          'd-if'
                        )}
                        href={getAddressHref(item)}
                        target="_blank"
                        rel="nofollow"
                      >
                        <Icon
                          name='location'
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                        />
                        {item.address}
                      </a>
                    </div>
                  )}

                  <div className={styles['map-with-offices_list-btn']}>
                    <button
                      className='link-arrow'
                      type='button'
                      onClick={() => handleShowOnMap(item)}
                    >
                      {t('onMap')}
                      <Icon
                        name='arrow'
                        width={12}
                        height={12}
                        viewBox="0 0 16 16"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {localPagination?.total > 1 && (
          <Pagination
            isLoading={isLoading}
            currentPage={currentPage}
            activePages={activePages}
            totalCount={localPagination.total}
            pageSize={localPagination.pageSize}
            onPageChange={onChangePage}
            onLoadMore={(page) => onChangePage(page, true)}
          />
        )}
      </div>
    </div>
  )
})

MapWithOffices.propTypes = {
  title: PropTypes.string,
  lang: PropTypes.string,
}

MapWithOffices.displayName = 'MapWithOffices'

export default MapWithOffices