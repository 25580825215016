'use client'
import {useTranslations} from 'next-intl'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Breadcrumbs from '@/app/components/Ui/Breadcrumbs'
import Icon from '@/app/components/Ui/Icon'
import MapWithOffices from '@/app/components/Sections/MapWithOffices'
import Tag from '@/app/components/Ui/Tag'

import styles from './ContactsMain.module.scss'

export default function ContactsMain({
  breadcrumbs,
  title,
  image,
  text,
  columns,
  mapTitle,
  lang
}) {
  const t = useTranslations('map')
  const officesRef = useRef(null)

  const handleShowOnMap = (place) => {
    if (!place) return

    officesRef.current.showOnMap(place)
  }

  return (
    <>
      <div
        className={classNames(
          styles['head'],
          image && styles['head--with-img']
        )}
      >
        <Breadcrumbs items={breadcrumbs} />
        <div className='wr'>
          <h1>{title}</h1>
          {text && (
            <div
              className={classNames(styles['head_text'], 'ws-pl')}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          {Array.isArray(columns) && columns.length !== 0 && (
            <div
              className={classNames(
                styles['head_list'],
                'd-f fw-w'
              )}
            >
              {image && <img className={styles['head_list-img']} src={image} alt='Image' loading='lazy' />}

              {columns.map((col, colIndex) => (
                <div
                  key={colIndex}
                  className={classNames(
                    styles['head_list-col'],
                    'd-f fd-c'
                  )}
                >
                  {col.items.map((item, index) => (
                    <div
                      key={index}
                      className={classNames(
                        styles['head_list-item'],
                        styles[`theme-${item.theme || 'blue'}`]
                      )}
                    >
                      <div className={styles['head_list-item-title']}>{item.title}</div>

                      {item.phone && (
                        <div className={styles['head_list-item-phone']}>
                          <div className={styles['head_list-item-phone-item']}>
                            <a
                              className='d-if ai-c h4'
                              href={`tel:${item.phone}`}
                            >
                              <Icon
                                name='phone-blue'
                                width={28}
                                height={28}
                                viewBox="0 0 24 24"
                              />
                              {item.phone}
                            </a>
                          </div>
                          <div className={styles['head_list-item-phone-descr']}>{item.phoneDescr}</div>
                        </div>
                      )}

                      {(item.email || item.address || item.timeWork) && (
                        <div className={styles['head_list-item-contacts']}>
                          {item.email && (
                            <div className={styles['head_list-item-contacts-item']}>
                              <a
                                className={classNames(
                                  styles['head_list-item-contacts-item-link'],
                                  'd-if'
                                )}
                                href={`mailto:${item.email}`}
                              >
                                <Icon
                                  name='email-at'
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                />
                                {item.email}
                              </a>
                            </div>
                          )}

                          {item.address && (
                            <div className={styles['head_list-item-contacts-item']}>
                              <Tag
                                as={item.addressLink ? 'a' : 'span'}
                                className={classNames(
                                  styles['head_list-item-contacts-item-link'],
                                  'd-if'
                                )}
                                href={item.addressLink}
                                target={item.addressLink && '_blank'}
                                rel={item.addressLink && 'nofollow'}
                              >
                                <Icon
                                  name='location'
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                />
                                {item.address}
                              </Tag>
                            </div>
                          )}

                          {item.timeWork && (
                            <div className={styles['head_list-item-contacts-item']}>
                              <span
                                className={classNames(
                                  styles['head_list-item-contacts-item-link'],
                                  'd-if'
                                )}
                              >
                                <Icon
                                  name='schedule'
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                />
                                <span className='ws-pl'>{item.timeWork}</span>
                              </span>
                            </div>
                          )}

                          {item.officeOnMap && (
                            <div className={styles['head_list-item-contacts-onmap']}>
                              <button
                                className='link-arrow'
                                type='button'
                                onClick={() => handleShowOnMap(item.officeOnMap)}
                              >
                                {t('onMap')}
                                <Icon
                                  name='arrow'
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <MapWithOffices
        ref={officesRef}
        title={mapTitle}
        lang={lang}
      />
    </>
  )
}

ContactsMain.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  })),
  title: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      phone: PropTypes.string,
      phoneDescr: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      addressLink: PropTypes.string,
      timeWork: PropTypes.string,
      theme: PropTypes.oneOf(['blue', 'pink_dark']),
      officeOnMap: PropTypes.object,
    })),
  })),
  mapTitle: PropTypes.string,
  lang: PropTypes.string,
}