import PropTypes from 'prop-types'

export default function Tag({ as = 'div', ...props }) {
  const Component = as;

  return <Component {...props} />
}

Tag.propTypes = {
  as: PropTypes.string
}
